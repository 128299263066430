.panelPage {
}
.panelPage p {
  margin: 0;
}
.panelPageContainer {
}
.panelPageContainer > div {
  display: inline-block;
}
.topNavigation {
  width: 100%;
  background: #3f51b5;
  color: #fff;
  padding: 10px 15px;
}
.topNavigation button {
  color: #fff;
}
.contentBody {
  padding: 15px;
}
.loaderScrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loaderScrapper p{ margin-left: 15px }